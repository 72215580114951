.logo {
  text-align: center;
  margin-top: 10px;
}
.logo > img {
  width: 65%;
}
.Component-body {
  background: #fff;
  margin-top: 10px;
  padding: 20px ;
}
.columnHide {
  display: none;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 8px 16px!important;
}
.card {
  background: #fff;
  margin-bottom: 10px;
}
.card .title {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #E9E9E9;
  padding: 20px;
}
.card .card_content {
  padding: 20px;
}
.echart_title {
  font-size: 16px;
  border-bottom: 1px solid #E9E9E9;
  margin-bottom: 10px;
  background: #E9E9E9;
  padding: 5px;
}
.word_set {
  width: 100px;
  word-break: break-all;
}
.text-right {
  text-align: right;
}
.echartBox {
  margin: 20px 0;
  overflow: hidden;
}
.echartBox .echarts {
  width: 50%;
  height: 300px;
  float: left;
}
.disable {
  display: none;
}
.ant-layout-sider-children {
  overflow-y: auto;
}
.filter {
  padding: 10px 20px 20px 20px;
  background: #fff;
}
.filter .ant-col-3 label {
  margin: 0px 10px;
  display: block;
  text-align: justify;
  text-align-last: justify;
}
.filter .btns {
  padding-left: 19px;
}
.filter .line {
  width: 1px;
  height: 81px;
  margin-top: 23.5px;
  background-color: #D8D8D8;
}
.filter-bar {
  overflow: hidden;
}
.filter-bar .items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.filter-bar .items .item {
  justify-content: center;
  display: flex;
  align-items: center;
  padding-right: 20px;
  margin-top: 10px;
}
.filter-bar .items .item .element-name {
  text-align: justify;
  padding-right: 10px;
}
.filter-bar .items .item .element_local {
  width: 130px;
  float: left;
}
.filter-bar .items .item .element {
  width: 180px;
}
.filter-bar .items .item .element .time {
  padding: 0 11px;
}
.filter-bar .items .item .element .times {
  padding: 0 11px;
}
.filter-bar .items .item .element input,
.filter-bar .items .item .element .ant-calendar-picker,
.filter-bar .items .item .element .ant-select,
.filter-bar .items .item .element .ant-calendar-picker-input,
.filter-bar .items .item .element .ant-picker-input {
  height: 30px;
  font-size: 12px;
  outline: none;
}
.filter-bar .items .item .element .birthDecade {
  width: 100%;
  height: 32px;
}
.filter-bar .items .item .element .ant-input:focus,
.filter-bar .items .item .element .ant-input:hover {
  border: 1px solid #d9d9d9;
  box-shadow: none;
}
.filter-bar .items .item .element .ant-select-focused {
  border: none;
}
.filter-bar .items .item .element .ant-calendar-picker-input {
  display: flex;
  align-items: center;
}
.filter-bar .items .item .element .ant-form-item {
  margin-bottom: 0px;
}
.filter-bar .items .item .element .ant-select-focused .ant-select-selection {
  box-shadow: none;
}
.filter-bar .items .item .element .ant-select-lg .ant-select-selection--single {
  padding: 0px;
  height: 100%;
}
.filter-bar .items .item .element .ant-select-lg .ant-select-selection__rendered {
  height: 100%;
  display: flex;
  align-items: center;
}
.filter-bar .btns {
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  float: right;
  margin-top: 20px;
}
.filter .ant-calendar-picker {
  width: 100%;
}
.filter .ant-input {
  background: #FFFFFF;
  border: 1px solid #DADEE4;
  border-radius: 4px;
  height: 30px;
  line-height: 30px;
  padding: 0px 3px 0px 10px;
}
.filter .ant-calendar-range-picker-separator {
  line-height: inherit;
}
.filter .ant-select {
  width: 100%;
}
.filter .ant-select-selection--single {
  height: 30px;
}
.filter .ant-select-selection--single .ant-select-selection__rendered {
  line-height: 30px;
}
.business-form-item {
  display: flex;
  width: 100%;
  align-items: center;
}
.table-col {
  font-size: 13px;
  width: 100%;
  border-collapse: collapse;
  color: #393A3E;
}
.table-col td {
  background-color: #fff;
  text-align: left;
  padding: 5px 10px;
  border: 1px solid #CED0DA;
  width: 150px;
  word-break: break-all;
}
.table-col td.key {
  background-color: #F4F4F4;
  font-weight: bold!important;
  width: 100px;
}
.chartTitle {
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
}
