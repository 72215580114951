.table-col{
    font-size: 13px;
    width: 100%;
    border-collapse: collapse;
    color:#393A3E;
    td{
        background-color: #fff;
        text-align: left;
        padding: 5px 10px;
        border:1px solid #CED0DA;
        width:150px;
        word-break: break-all;
    }
    td.key{
        background-color: #F4F4F4;
        font-weight: bold!important;
        width:100px;
    }
}