.filter{
	padding:  10px 20px 20px 20px;
	background: #fff;
}
.filter .ant-col-3 label{
    margin: 0px 10px;
    display: block;
    text-align: justify;
    text-align-last: justify;
}
.filter .btns{
	padding-left: 19px;
}
.filter .line{
	width: 1px;
	height: 81px;
	margin-top: 23.5px;
	background-color: #D8D8D8;
}

.filter-bar{
	overflow: hidden;
	.items{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		.item{
			justify-content: center;
			display: flex;
			align-items: center;
			padding-right: 20px;
			margin-top:10px;
			.element-name{
				text-align: justify;
				padding-right: 10px;
				
			}
			.element_local{
				width: 130px;
				float: left;
			}
			.element{
				width: 180px;
				.time{
					padding: 0 11px;
				}
				.times{
					padding: 0 11px;
				}
				input,.ant-calendar-picker,.ant-select,.ant-calendar-picker-input,.ant-picker-input{
					height: 30px;
					font-size: 12px;
					outline: none;
				}
				.birthDecade{
					width: 100%;
					height: 32px;
				}
				.ant-input:focus,.ant-input:hover{
					border:1px solid #d9d9d9;
					box-shadow: none;
				}
				.ant-select-focused{
					border:none;
				}
				.ant-calendar-picker-input{
					display: flex;
					align-items: center;
				}
				.ant-form-item{
					margin-bottom: 0px;
				}
				.ant-select-focused .ant-select-selection{
					box-shadow: none;
				}
				.ant-select-lg .ant-select-selection--single{
					padding: 0px;
					height: 100%;
				}
				.ant-select-lg .ant-select-selection__rendered{
					height: 100%;
					display: flex;
					align-items: center;
				}
			}
		}
	}
	.btns{
		flex-direction: column;
		align-items: flex-end;
		justify-content: space-around;
		float: right;
		margin-top:20px
	}
}
.filter .ant-calendar-picker{
	width: 100%;
}
.filter .ant-input{
	background: #FFFFFF;
	border: 1px solid #DADEE4;
	border-radius: 4px;
	height: 30px;
	line-height: 30px;
	padding: 0px 3px 0px 10px;
}

.filter .ant-calendar-range-picker-separator{
	line-height: inherit;
}
.filter .ant-select{
	width:100%;
}

.filter .ant-select-selection--single{
	height: 30px;
}
.filter .ant-select-selection--single .ant-select-selection__rendered{
	line-height: 30px;
}

// 表单业务级联组件样式
.business-form-item{
    display: flex;
    width: 100%;
    align-items: center;
}